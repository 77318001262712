import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import Typography from "@mui/material/Typography"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"

import { Audiotrack, HighlightOffRounded, Videocam } from "@mui/icons-material"
import { Grid, Link, Slide, TablePagination } from "@mui/material"
import { AUListItem, Order, HeadCell, AUListItemDoc } from "../../utils/models"
import {
  EnhancedTableHead,
  StyledTableRow,
  StyledTableCell,
  ShortTableCell,
  AUListCell,
  numberOfPages,
} from "../../utils/MUIExtentions/tableViewExtentions"
import { stableSort, getComparator } from "../../utils/sortUtils"

import AUPagination from "./AUPagination"
import { useEffect } from "react"

import { useTranslation } from "next-i18next"

export default function AUListTable({
  showsTypeColumn = false,
  showCellMenu = false,
  cellMenuItems = [],
  currentPage = 0,
  nameColumn,
  onCellCallback,
  onPageCallback = (page) => console.log("Page changed", page),
  rowsData,
  isLoading,
}) {
  const [order, setOrder] = React.useState<Order>("asc")
  const [orderBy, setOrderBy] = React.useState<keyof AUListItem>("name")
  const [page, setPage] = React.useState(currentPage)
  const [rows, setRows] = React.useState(rowsData)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [nextPageIsLoading, setNextPageIsLoading] = React.useState(true)
  const { t } = useTranslation()

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AUListItem
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  useEffect(() => {
    setRows(rowsData)
  }, [rowsData])

  useEffect(() => {
    setNextPageIsLoading(isLoading)
  }, [isLoading])

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageCallback(newPage - 1)
    setPage(newPage - 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  var headCells = new Array<HeadCell>()
  if (showsTypeColumn) {
    headCells = [
      {
        id: "isaudio",
        numeric: true,
        disablePadding: false,
        label: t("type"),
      },
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: nameColumn ? nameColumn : t("name"),
      },
    ]
  } else {
    headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: nameColumn ? nameColumn : t("name"),
      },
    ]
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ height: "100%", width: "100%", mb: 2, overflow: "hidden" }}>
        <TableContainer
          sx={{ position: "relative", maxHeight: "70vh", minHeight: 340 }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            {rows && (
              <EnhancedTableHead
                data={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            )}
            {!isLoading && (
              <TableBody key={"tb"}>
                {rows.length > 0 &&
                  rows &&
                  stableSort(
                    rows as { id: number | string; name: number | string }[],
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Slide
                          key={row.id}
                          direction="left"
                          in={true}
                          mountOnEnter
                          unmountOnExit
                        >
                          <StyledTableRow key={row.id}>
                            {showsTypeColumn && (
                              <ShortTableCell
                                size={"small"}
                                align="left"
                                key={row.id + "_c1"}
                              >
                                <IconButton key={row.id + "_c2"} size="large">
                                  {(row as AUListItemDoc).isaudio === true && (
                                    <Audiotrack sx={{ color: "#4d3e89" }} />
                                  )}
                                  {(row as AUListItemDoc).isaudio !== true && (
                                    <Videocam sx={{ color: "#4d3e89" }} />
                                  )}
                                </IconButton>
                              </ShortTableCell>
                            )}
                            <StyledTableCell
                              key={row.id + "_c"}
                              align="left"
                              sx={{ padding: "6px 16px" }}
                            >
                              <AUListCell
                                row={row}
                                onCellCallback={onCellCallback}
                                showCellMenu={showCellMenu}
                                cellMenuItems={cellMenuItems}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </Slide>
                      )
                    })}
              </TableBody>
            )}
          </Table>
          {rows.length == 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
                opacity: 0.5,
              }}
            >
              <HighlightOffRounded
                sx={{ fontSize: "5rem" }}
                color="primary"
              ></HighlightOffRounded>
              <Typography variant="body2">{t("no_entries")}</Typography>
            </Box>
          )}
        </TableContainer>
        {rows && (
          <Stack
            sx={{
              justifyContent: "end",
              alignItems: "center",
              paddingBottom: "10px",
              paddingRight: "10px",
            }}
            direction="row"
            spacing={2}
          >
            <Typography
              sx={{ fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: 600 }}
            >
              {t("table_pagination", {
                currentPage: page + 1,
                maxPages: numberOfPages(rows.length, rowsPerPage),
              })}
            </Typography>
            <Pagination
              count={numberOfPages(rows.length, rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
            />
          </Stack>
        )}
      </Paper>
    </Box>
  )
}
