import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material"
import { Done, ExpandLess, ExpandMore, WarningAmber } from "@mui/icons-material"
import { WorkerStatus } from "../utils/models"
import { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { getDocStatus, getTaskStatus } from "../utils/api/aurekaAPI"
import theme from "../../src/theme"
import { X } from "react-feather"

enum StatusType {
  REENCODING = "reencoding",
  TRANSCRIBING = "transcribing",
}

interface DocsStatus {
  docName: string
  docId: number
  statusType: StatusType
  statusID: string
  status?: WorkerStatus
}

const TranscribeStatusAccordion = () => {
  const { t } = useTranslation()
  const [docsStatus, setDocsStatus] = useState<DocsStatus[]>([])
  const [hide, setHide] = useState(false)
  const [expand, setExpand] = useState(false)
  let docStatusInterval

  useEffect(() => {
    getStatus()
    docStatusInterval = setInterval(getStatus, 10000)

    return () => {
      clearInterval(docStatusInterval)
    }
  }, [])

  const getStatus = () => {
    getDocStatus()
      .then((res: any) => {
        let allStats: DocsStatus[] = []
        const statusCount = res.data.length
        res.data.forEach((doc, i) => {
          getTaskStatus(doc.status_id).then((res: any) => {
            let s: DocsStatus = {
              docName: doc.doc_name,
              docId: doc.fk_document,
              statusType: doc.status_type as StatusType,
              statusID: doc.status_id,
              status: res.data as WorkerStatus,
            }
            allStats.push(s)
            if (allStats.length == statusCount) {
              setDocsStatus(
                allStats.sort((a, b) => a.docName.localeCompare(b.docName))
              )
            }
          })
        })
      })
      .catch((error) => console.log(error))
  }

  const hideAccordion = (e) => {
    e.preventDefault()
    setHide(true)
    clearInterval(docStatusInterval)
  }

  return (
    <>
      {docsStatus.length > 0 && (
        <Box>
          {!hide && (
            <Accordion
              sx={{
                position: "fixed",
                bottom: 0,
                left: 10,
                width: {
                  xs: "50%",
                  md: "30%",
                },
                borderTopLeftRadius: "6px !important",
                borderTopRightRadius: "6px !important",
                zIndex: (theme) => theme.zIndex.drawer - 1,
              }}
              expanded={expand}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                  borderTopLeftRadius: "6px !important",
                  borderTopRightRadius: "6px !important",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2" fontWeight={600}>
                    {t("transcribe_status_accordion_title")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {expand ? (
                      <ExpandMore onClick={() => setExpand(!expand)} />
                    ) : (
                      <ExpandLess onClick={() => setExpand(!expand)} />
                    )}
                    <X size={"1em"} onClick={(e) => hideAccordion(e)} />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {docsStatus.length > 0 ? (
                  <List
                    dense
                    sx={{
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      sx={{ height: "100%" }}
                      rowSpacing={1}
                    >
                      {docsStatus.map((doc, i) => (
                        <React.Fragment key={i}>
                          <Grid item xs={10}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  overflowWrap: "break-word",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {doc.docName}
                              </Typography>
                              <Typography
                                sx={{
                                  overflowWrap: "break-word",
                                  fontSize: "0.65rem",
                                  fontWeight: 600,
                                }}
                              >
                                {doc.statusType == StatusType.REENCODING
                                  ? t("preprocessing_status")
                                  : t("transcribing_status")}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {(doc.status == WorkerStatus.PENDING ||
                              doc.status == WorkerStatus.STARTED) && (
                              <CircularProgress size={20}></CircularProgress>
                            )}
                            {doc.status == WorkerStatus.SUCCESS && (
                              <Done color="success"></Done>
                            )}
                            {doc.status == WorkerStatus.FAILURE && (
                              <Icon
                                sx={{
                                  minWidth: "25px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mx: 1,
                                }}
                              >
                                <Tooltip
                                  placement="right"
                                  title={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0",
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        color="inherit"
                                      >
                                        {doc.statusType == StatusType.REENCODING
                                          ? t("failed_to_preprocess_error")
                                          : t("failed_to_transcribe_error")}
                                      </Typography>
                                    </div>
                                  }
                                >
                                  <WarningAmber color="warning" />
                                </Tooltip>
                              </Icon>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </List>
                ) : (
                  <Typography variant="body2">{t("no_entries")}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      )}
    </>
  )
}

export default TranscribeStatusAccordion
