import * as React from "react"
import { useRouter } from "next/router"
import { Fab } from "@mui/material"

export interface AUDashboardButtonProps {
  icon: JSX.Element
  action: () => void
}

export default function AUDashboardButton({
  icon,
  action,
}: AUDashboardButtonProps) {
  const router = useRouter()

  return (
    <Fab
      onClick={action}
      sx={{ position: "absolute", bottom: "5%", right: "-5%" }}
      color="primary"
      aria-label="add"
    >
      {icon}
    </Fab>
  )
}
